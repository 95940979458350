import Link from 'next/link';

import Button from '@/components/common/Button/Button';
import { useStore } from '@/models/root-store';
import { Mode } from '@/utils/constants';

export type CartHeaderTypes = {
  total: number;
  MOV: number;
  mode?: Mode | undefined;
  closeCart: () => void;
  variation: boolean;
  isValid?: boolean;
};

const CartHeader: React.FC<CartHeaderTypes> = ({
  total,
  MOV,
  mode,
  closeCart,
  variation,
}: CartHeaderTypes) => {
  const { cartStore } = useStore();
  const { cartToUse } = cartStore;
  const { isEmpty } = cartToUse;
  const renderHeader = (renderMode: Mode | undefined) => {
    switch (renderMode) {
      case Mode.EDIT_SUBSCRIPTION:
        return 'Edit Order';
      default:
        return 'Your Cart';
    }
  };

  // customise the href based on the menu-builder plan
  const { menuBuilderPlan } = cartToUse;
  const href =
    menuBuilderPlan?.isSavedTimePlan && mode !== Mode.EDIT_SUBSCRIPTION
      ? {
          pathname: '/meal-plan',
          query: {
            planId: menuBuilderPlan.planId,
            mode: mode || Mode.EDIT_CART,
          },
        }
      : {
          pathname: '/menu/meals',
          query: { ...(mode && { mode }) },
        };

  return (
    <div className="z-50 flex w-full flex-row justify-between pr-3">
      <div className="flex items-center">
        <h2 className="font-interMedium text-lg leading-6 text-black">
          {renderHeader(mode)}
        </h2>
      </div>

      {variation ? (
        <div className="flex items-center">
          <p className="flex flex-col font-interBold text-xs tracking-wide">
            <p>
              <span className="pr-1 text-sm">Total ${total.toFixed(2)}</span>
            </p>
            {mode !== Mode.EDIT_SUBSCRIPTION && (
              <p className="pr-1 font-interMedium text-xxs text-grey-dark">
                {!(total >= MOV) ? (
                  <span>Min. order value ${MOV}</span>
                ) : (
                  <span>(Excl. Shipping)</span>
                )}
              </p>
            )}
          </p>
        </div>
      ) : (
        !isEmpty && (
          <div>
            <Button
              type="button"
              theme="quaternary"
              onClick={closeCart}
              overrideClass="border-2 border-black py-1 gap-2 rounded-full px-4 text-base font-interMedium flex items-center justify-center min-w-16"
            >
              <Link href={href} legacyBehavior>
                <span className="font-interMedium text-base">+ Add more</span>
              </Link>
            </Button>
          </div>
        )
      )}
    </div>
  );
};

export default CartHeader;
