import { observer } from 'mobx-react-lite';
import React from 'react';

import Button from '@/components/common/Button/Button';
import { Modal } from '@/components/common/Modal/Modal';
import { useStore } from '@/models/root-store';

type WlpModalProps = {
  isOpen?: boolean;
  onClose: () => void;
};

const WlpModal: React.FC<WlpModalProps> = observer(
  ({ isOpen = false, onClose }: WlpModalProps) => {
    const {
      customerStore: { customer },
    } = useStore();
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={() => onClose()}
        customPadding="p-4"
      >
        <div className="relative my-0 py-0">
          <div className="custom-scroll text-center font-inter lg:px-8 lg:py-6 ">
            <h2 className="mx-auto mb-8 max-w-sm pt-4 font-interMedium text-xl text-black md:text-5xl md:leading-9">
              Hey {customer.firstName}!
            </h2>
            <div className="mb-10 grid gap-8 font-interSemibold text-base md:text-lg md:leading-5">
              <p>
                Your current subscription is for our Weight Loss Program, which
                is exclusive to our mobile app.
              </p>
              <p>Open up the MYMC App to manage your subscription.</p>
            </div>
            <div className="flex items-center justify-center">
              <Button
                className="w-40"
                theme="primary"
                onClick={() => onClose()}
              >
                Got it
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);

export default WlpModal;
