import { observer } from 'mobx-react-lite';
import { getPathname } from 'next/dist/lib/url';
import { useRouter } from 'next/router';
import React from 'react';

import CancelButton from '@/components/Search/CancelButton';
import SearchInput from '@/components/Search/SearchInput';
import { useStore } from '@/models/root-store';
import { SearchModes, SearchState } from '@/utils/constants';
import { getSearchMode, matchUrlPathname } from '@/utils/helpers';

interface SearchBarProps {
  setBlur: (isBlur: boolean) => void;
}
const SearchBar: React.FC<SearchBarProps> = observer(({ setBlur }) => {
  const {
    productStore: {
      filters: { clearFilters },
      search: { resetSearch, setSearchState, referrer, searchState },
    },
  } = useStore();
  const router = useRouter();
  const handleCancel = async () => {
    setBlur(false);
    const siteUrl =
      process.env.NEXT_PUBLIC_ENV === 'staging'
        ? process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL
        : process.env.NEXT_PUBLIC_SITE_URL;
    const searchMode = getSearchMode(router.asPath as string);
    const referrerPath = getPathname(referrer);
    setSearchState(SearchState.CANCELLING);
    switch (searchMode) {
      case SearchModes.MENU_SEARCH:
      case SearchModes.Menu:
        if (!referrer) {
          await router.push('/menu/meals');
          break;
        }
        if (
          matchUrlPathname(referrer, siteUrl) &&
          referrerPath.includes('menu') &&
          !referrerPath.includes('search')
        ) {
          await router.push(referrer);
        } else {
          await router.push('/menu/meals');
        }

        break;
      case SearchModes.SWAP_EDIT_CART:
      case SearchModes.SWAP_EDIT_CART_SEARCH:
        await router.push('/swap?mode=edit-cart');
        break;
      case SearchModes.Swap:
      case SearchModes.SWAP_SEARCH:
        await router.push('/swap');
        break;
      case SearchModes.EDIT_SUBSCRIPTION_SEARCH:
        await router.push('/menu/meals?mode=edit-subscription');
        break;
      case SearchModes.SWAP_EDIT_SUBSCRIPTION_SEARCH:
        await router.push('/swap?mode=edit-subscription');
        break;
      default:
        break;
    }
    resetSearch();
    clearFilters();
    setSearchState(SearchState.NULL);
  };

  const expanded = searchState !== SearchState.NULL;
  return (
    <div
      className={`max-w-[380px] pr-1.5   md:max-w-[470px] md:px-4  mobile-320:pr-0`}
    >
      <div
        className={`flex w-full justify-center  ${expanded ? 'gap-4 px-0 md:gap-8 mobile-320:px-1.5 mobile-375:gap-2' : 'gap-8'}`}
      >
        <SearchInput setBlur={setBlur} />
        <CancelButton onClick={handleCancel} />
      </div>
    </div>
  );
});

export default SearchBar;
