import React from 'react';

import { Mode } from '@/utils/constants';

export type OrderSummaryContainerType = {
  cart: any;
};

const OrderSummaryContainer: React.FC<OrderSummaryContainerType> = ({
  cart,
}: OrderSummaryContainerType) => {
  const {
    lineItemTotal,
    subTotal,
    numberOfItems,
    discount,
    discountedAmount,
    mode,
  } = cart;

  return (
    <div className="relative w-full bg-white">
      <div className="sticky w-full overflow-hidden rounded bg-inherit">
        <div className="px-6 py-4 pt-0">
          <div className="flex flex-col gap-4 rounded-xl bg-off-grey p-4 font-interMedium">
            <div className="flex justify-between font-interMedium">
              <p className="text-xs text-black">
                Subtotal ({numberOfItems} items)
              </p>
              <p className="text-xs text-black">${lineItemTotal.toFixed(2)}</p>
            </div>
            {discount > 0 && (
              <div className="flex justify-between">
                <p className="text-xs text-black">Order Discount</p>
                <p className="text-xs text-success-copy">
                  -${discountedAmount.toFixed(2)}
                </p>
              </div>
            )}
            {mode !== Mode.EDIT_SUBSCRIPTION && (
              <div className="flex justify-between">
                <p className="-mb-2 font-interMedium text-xs leading-4 text-grey-dark">
                  Shipping & promotions applied at checkout.
                </p>
              </div>
            )}
            {/* frequency === Frequency.ONCE ? (
              <div className="flex items-center justify-center rounded-md bg-rhubarb-lite py-1.5 text-xs text-rhubarb-dark">
                <Image
                  src="/assets/images/svg/shipping.svg"
                  alt="Shipping"
                  width={24}
                  height={15}
                />
                <p className="pl-2.5 font-interMedium">
                  Get FREE SHIPPING when you subscribe 🎉
                </p>
              </div>
            ) : null */}
            {/* <div className="flex justify-between text-xs text-black"> */}
            {/*  <p>GST (incl)</p> */}
            {/*  <p className="text-base">${gst.toFixed(2)}</p> */}
            {/* </div> */}
            <div className="flex justify-between font-interSemibold text-base text-black">
              <p>Total (incl. GST)</p>
              <p>${subTotal.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummaryContainer;
