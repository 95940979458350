import Image from 'next/image';

import { useStore } from '@/models/root-store';

const EducationBanner = () => {
  const { generalStore } = useStore();
  const deliveryHeader = generalStore.getTipByKey('recurring-delivery-heading');
  return (
    <div className="flex items-center justify-center bg-off-white py-4 font-interSemibold text-xs leading-3">
      <Image
        src="/assets/icons/delivery_truck.svg"
        width={25}
        height={20}
        className="mr-1"
        alt="Delivery Image"
      />
      <div
        className="text-xs leading-3"
        dangerouslySetInnerHTML={{ __html: deliveryHeader }}
      ></div>
    </div>
  );
};

export default EducationBanner;
